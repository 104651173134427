import axios from '@axios'

export default {
  namespaced: true,
  state: {
    respData: null,
    respDataWarehouse: null,
    respDataStockInWarehouse: null,
  },
  getters: {
  },
  mutations: {
    GET(state, payload) {
      state.respData = payload
    },
    GET_WAREHOUSE(state, payload) {
      state.respDataWarehouse = payload
    },
    GET_STOCK_IN_WAREHOUSE(state, payload) {
      state.respDataStockInWarehouse = payload
    },
    ADD(state, payload) {
      state.respData.data.unshift(payload)
    },
    REMOVE(state, id) {
      if (state.respData != null) {
        const ItemIndex = state.respData.data.findIndex(p => p._id === id)
        state.respData.data.splice(ItemIndex, 1)
        // eslint-disable-next-line operator-assignment
        state.respData.max = state.respData.max - 1
      }
    },
  },
  actions: {
    get({ commit }, item) {
      return new Promise((resolve, reject) => {
        const params = Object.fromEntries(
          Object.entries({
            startDate: item.startDate,
            endDate: item.endDate,
            pageSize: item.pageSize,
            currentPage: item.currentPage,
            searchQuery: item.searchQuery,
            warehouse: Array.isArray(item.warehouse) ? item.warehouse.join(',') : item.warehouse,
            categories: Array.isArray(item.categories) ? item.categories.join(',') : item.categories,
            brands: Array.isArray(item.brands) ? item.brands.join(',') : item.brands,
          }).filter(([_, value]) => value !== null && value !== undefined && value !== ''),
        )

        console.log(params)
        axios.get('/api/web/application/eCommerce/warehouseProduct/get', { params })
          .then(response => {
            commit('GET', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getWarehouse({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get('/api/web/application/eCommerce/warehouseProduct/getWarehouse?pageSize=0&currentPage=1&searchQuery=')
          .then(response => {
            commit('GET_WAREHOUSE', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getStockInWarehouse({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/application/eCommerce/warehouseProduct/getStockInWarehouse?id=${item.id}&pageSize=${item.pageSize}&currentPage=${item.currentPage}&searchQuery=${item.searchQuery}`)
          .then(response => {
            commit('GET_STOCK_IN_WAREHOUSE', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    view({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/application/eCommerce/warehouseProduct/view?id=${item.id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    add({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.post('/api/web/application/eCommerce/warehouseProduct/add', item)
          .then(response => {
            commit('ADD', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    update({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('/api/web/application/eCommerce/warehouseProduct/update', item)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    delete({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.delete(`/api/web/application/eCommerce/warehouseProduct/delete?id=${item.id}`)
          .then(response => {
            commit('REMOVE', item)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    transfer({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('/api/web/application/eCommerce/warehouseProduct/transfer', item)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    import({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('/api/web/application/eCommerce/warehouseProduct/import', item)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    adjust({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('/api/web/application/eCommerce/warehouseProduct/adjust', item)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    transferOut({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('/api/web/application/eCommerce/warehouseProduct/transferOut', item)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    checkStock({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.post('/api/web/application/eCommerce/warehouseProduct/checkStock', item)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    deleteSelectedData({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('/api/web/application/eCommerce/warehouseProduct/deleteSelectedData', item)
          .then(response => {
            // commit('UPDATE', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    updateCSVStock({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('/api/web/application/eCommerce/warehouseProduct/updateCSVStock', item)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    getCSV({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get('/api/web/application/eCommerce/warehouseProduct/getCSV')
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
